import React from 'react';
import data from './data';

const Insurance = ({ page }) => {
    // Retrieve the page data based on the provided insurance type
    const pageData = data[page];

    return (
        <div className="container">
            {pageData.sections.map((section, index) => (
                <div key={index} style={{ marginBottom: '20px', padding: '10px', borderBottom: '1px solid #ddd' }}>
                    <h2 style={{ textAlign: 'center' }}>{section.header}</h2>
                    
                    {section.content && (
                        <p>{section.content}</p>
                    )}

                    {section.list && (
                        <ul>
                            {section.list.map((item, i) => (
                                <li key={i}>{item}</li>
                            ))}
                        </ul>
                    )}

                    {section.subsections && (
                        <div>
                            {section.subsections.map((subsection, j) => (
                                <div key={j} style={{ marginBottom: '10px' }}>
                                    <h3>{subsection.name}</h3>
                                    <p>{subsection.description}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Insurance;
