import { FaLinkedin, FaFacebookF, FaInstagram } from 'react-icons/fa6'
//import logoAlt from './cfaLogoAlt.png'

const Footer = () => {
    return (
        <div style={{ backgroundColor: '#001314', color: 'white', borderTop: '2px solid white', }}>
            <br />
            <div className='container ' style={{ padding: '25px' }}>
                <div className="col-sm-12">
                    If you are vision-impaired or have some other impairment and you wish to discuss potential accommodations related to using this website, please email us at <a href="mailto:accessibility@insuranceworks.agency"> accessibility@insuranceworks.agency</a>
                    .
                    <br />
                    <br />
                    This website is not affiliated with the government. The information provided on this website is for informational purposes only. It is not intended to be, nor does it constitute any kind of financial advice. Please seek advice from a qualified professional prior to making any financial decisions based on the information provided. This website acts as an independent digital media & advertising publisher. This webpage is formatted as an advertorial. An advertorial is an advertisement that is written in an editorial news format. PLEASE BE AWARE THAT THIS IS AN ADVERTISEMENT AND NOT AN ACTUAL NEWS ARTICLE, BLOG, OR CONSUMER PROTECTION UPDATE. This website MAY RECEIVE PAID COMPENSATION FOR CLICKS OR SALES PRODUCED FROM THE CONTENT FOUND ON THIS WEBPAGE. This compensation may affect which companies are displayed, the placement of advertisements, and their order of appearance. Any information, discounts, or price quotations listed may not be applicable in your location or if certain requirements are not met. Additionally, our advertisers may have additional qualification requirements.
                    <br />
                    <br />
                    Our goal is to provide exceptional service. One of our agents may reach out to you to discuss your order, ask for feedback, and/or see if you need any assistance with your products, services, or plans, at the phone number you provided regardless of your do-not-call list status. You may opt-out of further contact at any time by simply telling our customer service team that you would no longer like to be contacted. In the event that our team is unable to reach you by phone, they may send you a text message letting you know that we called. Both our text messages and phone calls may be sent or connected utilizing automated software. Carrier charges may apply. You may opt-out of any future contact via text message by replying anytime with "STOP."
                    <br />
                    <br />
                    © 2024 Insurance Works Agency. All rights reserved
                    <br />
                    <img src="logo.png" alt="logo" style={{ height: '60px', maxWidth: '60vw', objectFit: 'contain' }} />
                </div>

            </div>
        </div>
    )
}

export default Footer;