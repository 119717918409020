// filename: Form.js

import React from 'react';

const Form = () => {
    return (
        <div style={{justifyContent: 'center', display: 'flex'}}>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe52PHJF3pqgjC-BTOO76ZT2YPL7W9CH_4DgEYLvV1XV0hvOw/viewform?embedded=true" width="640" height="2132" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    );
}

export default Form;