import React from 'react';

const Closing = () => {
    const heroImageStyle = {
        backgroundColor: '#004b4f',
        padding: '50px 0',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    };

    const heroTextStyle = {
        textAlign: 'center',
        color: 'white',
    };

    return (
        <div>
            <div style={heroImageStyle} className="d-flex align-items-center justify-content-center p">
                <div style={heroTextStyle} className="text-center">
                    <div style={{ fontSize: '45px', fontWeight: 'bold',  }}>
                        Take Advantage of the options available to you.

                    </div>
                    <div className='container' style={{ fontSize: '20px', }}>
                        Speak with a licensed agent and see how Insurance Works for you.
                    </div>
                    <br /><br />
                    <a href="/get-a-quote" style={{ display: 'flex', justifyContent: 'center' }}>

                        <button className='shadow' style={{ border: 'white 3px solid', backgroundColor: '#0b3233', color: 'white', padding: '10px 20px', borderRadius: '25px', width: '150px', textDecoration: 'none' }}>Get A Quote</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Closing;
