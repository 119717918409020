const data = {
  health: {
    sections: [
      {
        header: "What is Term Life Insurance?",
        content: "Term life insurance provides coverage for a set period, in contrast to whole life insurance, which requires scheduled payments for your entire life. Common term lengths include 10, 20, or 30 years. This policy type offers competitive and dependable quotes, and the premiums remain the same unless adjusted by the policyholder. If the policyholder passes away during the term, the beneficiary receives the death benefit."
      },
      {
        header: "Key Features of Term Life Insurance",
        list: [
          "Choose the term length that best suits your lifestyle.",
          "Premiums remain the same throughout the policy term unless changed.",
          "Death benefit is paid to beneficiaries if the policyholder passes within the term.",
          "Term life insurance quotes are competitive and dependable."
        ]
      },
      {
        header: "Types of Term Life Insurance",
        subsections: [
          {
            name: "Fixed Term",
            description: "The most popular choice; a straightforward plan that lasts 10, 20, or 30 years with static premiums."
          },
          {
            name: "Increasing Term",
            description: "Increases the death benefit over time, with slightly higher premiums. Often results in a larger payout."
          },
          {
            name: "Decreasing Term",
            description: "Premiums decrease over time, resulting in a smaller death benefit. Suitable for those with decreasing financial obligations."
          },
          {
            name: "Annual Renewable",
            description: "Offers yearly coverage with an option to renew, though premiums increase with each renewal. Ideal for short-term coverage."
          },
          {
            name: "Group Term Life",
            description: "Purchased through an employer and covers eligible employees as a workplace plan. Often available to families as well."
          }
        ]
      },
      {
        header: "More About Whole Life Insurance",
        list: [
          "Covers you for a lifetime, unlike term life insurance.",
          "Builds cash value that grows tax-deferred and can be borrowed against.",
          "Dividends are not guaranteed, and borrowing reduces cash value and death benefits."
        ]
      },
      {
        header: "What is Final Expense Life Insurance?",
        content: "Final expense life insurance is designed to cover end-of-life costs, including funeral expenses. Funerals can be costly, with expenses typically ranging from $8,000 to $10,000 or more. Final expense insurance provides peace of mind and may be used to cover funeral costs, medical bills, mortgage payments, or other expenses. It’s ideal for those who may not qualify for larger policies due to age or health conditions."
      },
      {
        header: "Final Expense Insurance: Coverage and Cost",
        list: [
          "Coverage amounts usually range from $2,000 to $50,000.",
          "No medical exam required; coverage begins after a few questions.",
          "Policies are generally more affordable and easier to get approved.",
          "Some plans have a waiting period of 1 to 3 years before benefits take effect."
        ]
      },
      {
        header: "How Does an Indexed Universal Life (IUL) Policy Work?",
        list: [
          "A type of permanent insurance that builds cash value based on a stock index.",
          "Offers premium flexibility and potential to achieve zero-cost premiums.",
          "Interest calculated based on equity indexes like the S&P 500 or NASDAQ, with a guaranteed minimum."
        ]
      },
      {
        header: "Comparing Life Insurance Options",
        subsections: [
          {
            name: "Indexed Universal Life (IUL) vs. Term Life Insurance",
            description: "Term life is simpler and more affordable, providing coverage for a set period. IUL offers lifetime coverage with cash value tied to stock indexes, making it more complex."
          },
          {
            name: "Indexed Universal Life (IUL) vs. Whole Life Insurance",
            description: "Whole life insurance builds cash value on a fixed schedule, is generally less costly than IUL, but lacks premium flexibility."
          },
          {
            name: "Indexed Universal Life (IUL) vs. Variable Life Insurance",
            description: "Variable life insurance is highly flexible but riskier, with cash value depending on selected stocks. Premiums can fluctuate based on stock performance."
          }
        ]
      }
    ]
  },
  medicare: {
    sections: [
      {
        header: "What is Medicare?",
        content: "Medicare is a federal health insurance program primarily for individuals 65 or older, but it also provides coverage for certain younger individuals with disabilities or specific health conditions. It helps pay for various healthcare services, such as hospital care, doctor visits, and prescription drugs."
      },
      {
        header: "Who Qualifies for Medicare?",
        list: [
          "Individuals 65 or older, regardless of employment status.",
          "Younger individuals with qualifying disabilities, eligible after 24 months of receiving Social Security disability benefits.",
          "Anyone diagnosed with end-stage renal disease (ESRD) or amyotrophic lateral sclerosis (ALS) at any age."
        ]
      },
      {
        header: "Medicare vs Medicaid and Dual Eligibility",
        content: "Medicare and Medicaid are both government programs that help pay for healthcare, but they are different. Some individuals qualify for both Medicare and Medicaid, a situation known as 'dual eligibility.' These benefits can work together to cover most healthcare costs."
      },
      {
        header: "Differences Between Medicare and Medicaid",
        subsections: [
          {
            name: "Medicare",
            description: "A federal health insurance program for people who are 65 or older, under 65 with certain disabilities, or of any age with End Stage Renal Disease (ESRD) or ALS."
          },
          {
            name: "Medicaid",
            description: "A joint federal and state program that provides health care coverage for individuals and families with limited income and resources."
          }
        ]
      },
      {
        header: "What is Covered by Medicare vs. Medicaid?",
        subsections: [
          {
            name: "Medicare",
            description: "Medicare coverage depends on the plan you choose and may include:\n- Part A: Hospital or skilled nursing facility care.\n- Part B: Outpatient care, doctor visits, and some preventive services.\n- Part D: Prescription drug coverage.\n- Part C: Medicare Advantage plans that combine Part A, Part B, and sometimes Part D."
          },
          {
            name: "Medicaid",
            description: "Medicaid coverage varies by state, but it typically includes:\n- Hospital and skilled nursing facility services.\n- Services in federally-qualified health centers and rural health clinics.\n- Doctor and certified midwife services."
          }
        ]
      },
      {
        header: "Can You Get Medicaid if You Have Medicare?",
        content: "Yes, it is possible to qualify for both Medicare and Medicaid. This is known as 'dual eligibility,' and when you are dual eligible, Medicare and Medicaid can often work together to cover most of your healthcare expenses."
      },
      {
        header: "Dual Eligibility",
        content: "Dual eligibility allows individuals to receive benefits from both Medicare and Medicaid. Dual Special Needs Plans (D-SNPs) are a type of Medicare plan specifically designed for people who are dual eligible, helping to coordinate benefits and reduce out-of-pocket costs."
      }
    ]
  },
  life: {
    sections: [
      {
        header: "What is Term Life Insurance?",
        content: "Term life insurance provides coverage for a set period, in contrast to whole life insurance, which requires scheduled payments for your entire life. Common term lengths include 10, 20, or 30 years. This policy type offers competitive and dependable quotes, and the premiums remain the same unless adjusted by the policyholder. If the policyholder passes away during the term, the beneficiary receives the death benefit."
      },
      {
        header: "Key Features of Term Life Insurance",
        list: [
          "Choose the term length that best suits your lifestyle.",
          "Premiums remain the same throughout the policy term unless changed.",
          "Death benefit is paid to beneficiaries if the policyholder passes within the term.",
          "Term life insurance quotes are competitive and dependable."
        ]
      },
      {
        header: "Types of Term Life Insurance",
        subsections: [
          {
            name: "Fixed Term",
            description: "The most popular choice; a straightforward plan that lasts 10, 20, or 30 years with static premiums."
          },
          {
            name: "Increasing Term",
            description: "Increases the death benefit over time, with slightly higher premiums. Often results in a larger payout."
          },
          {
            name: "Decreasing Term",
            description: "Premiums decrease over time, resulting in a smaller death benefit. Suitable for those with decreasing financial obligations."
          },
          {
            name: "Annual Renewable",
            description: "Offers yearly coverage with an option to renew, though premiums increase with each renewal. Ideal for short-term coverage."
          },
          {
            name: "Group Term Life",
            description: "Purchased through an employer and covers eligible employees as a workplace plan. Often available to families as well."
          }
        ]
      }
    ]
  },
  dental: {
    sections: [
      {
        header: "Prepaid Dental Plan",
        content: "Prepaid dental plans pay benefits only when you use network providers. These plans typically have no deductible or annual maximum, with most preventive care at no charge. For dental procedures listed on the copayment schedule, you pay a fixed copayment."
      },
      {
        header: "Prepaid Dental Plan Options",
        list: [
          "CIGNA Dental (4034)",
          "Sun Life Prepaid 225 (4025)",
          "Humana HD205 (4044)"
        ]
      },
      {
        header: "Standard PPO Dental Plan",
        content: "Standard PPO dental plans allow you to receive care from any dentist, but your costs are lower when you use network dentists. You typically have an annual deductible to meet before the plan starts paying benefits, after which you pay part of the cost for the services you receive."
      },
      {
        header: "Standard PPO Dental Plan Options",
        list: [
          "Ameritas Standard PPO (4022)",
          "MetLife Standard PPO (4032)",
          "Humana Standard PPO (4092)"
        ]
      },
      {
        header: "Preventive PPO Dental Plan",
        content: "With a preventive PPO dental plan, you can receive care from any dentist, though your costs are lower when using network dentists. You typically have an annual deductible, and once met, you pay part of the cost for the services you receive."
      },
      {
        header: "Preventive PPO Dental Plan Options",
        list: [
          "Ameritas Preventive PPO (4023)",
          "MetLife Preventive PPO (4033)",
          "Humana Preventive (4094)"
        ]
      },
      {
        header: "Indemnity with PPO Dental Plan",
        content: "Indemnity with PPO dental plans allow you to receive care from any dentist. Your costs are lower when using network dentists. You will typically need to meet an annual deductible before the plan starts paying benefits, and then you pay a percentage of the cost for the care you receive."
      },
      {
        header: "Indemnity with PPO Dental Plan Options",
        list: [
          "Ameritas Indemnity w/ PPO (4021)",
          "Sun Life Indemnity PPO (4074)",
          "MetLife Indemnity w/ PPO (4031)",
          "Humana Indemnity PPO (4090)"
        ]
      },
      {
        header: "Indemnity Dental Plan",
        content: "Indemnity dental plans allow you to receive care from any dentist. You must meet a deductible and then pay part of the cost for the services you receive."
      },
      {
        header: "Indemnity Dental Plan Option",
        list: [
          "Humana Schedule B (4084)"
        ]
      }
    ]
  }
};

export default data;
