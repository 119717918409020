import Footer from "../Footer";
import Nav from "../Nav";
import Body from "../FAQ/Body";
import Header from "../HomePage/Header";




const FAQ = () => {
    return (
        <div style={{}}>
            <Body />


        </div>
    );
}

export default FAQ;