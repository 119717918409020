import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import bodyPhoto from '../computer.jpg';

const Body = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <div>
            <div
                className="container"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <br /><br />
                <div
                    style={{
                        fontSize: '40px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    Frequently Asked Questions (FAQs) About Burial, Dental, and Life Insurance
                </div>
                <br />
                <div
                    style={{
                        maxWidth: '800px',
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    These insurance solutions offer financial peace of mind for you and your loved ones. Whether covering funeral expenses, managing healthcare needs with dental plans, or securing life insurance for long-term security, these policies help prepare for the unexpected.
                </div>
            </div>

            <br />
            <hr />
            <br />

            <div style={{ display: 'flex', justifyContent: 'center', color: 'black' }}>
                <div className="row container justify-content-center" style={{ padding: '25px 0' }}>
                    <div className="col-sm-12 col-md-6" style={{ fontSize: '15px', marginBottom: '25px' }}>
                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Why do I need burial insurance?
                        </div>
                        <br />
                        Burial insurance is affordable and easy to qualify for, helping families cover funeral costs without dipping into savings. It prevents loved ones from having to pay funeral expenses out of pocket.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Can burial insurance and life insurance work together?
                        </div>
                        <br />
                        Yes! Burial insurance covers funeral expenses, while life insurance provides broader financial protection, covering debts, mortgages, or education. Combining both ensures comprehensive coverage.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Can Medicare dental plans reduce healthcare costs?
                        </div>
                        <br />
                        Medicare dental plans help cover routine exams, cleanings, and essential dental procedures, reducing out-of-pocket healthcare expenses and supporting long-term oral health.
                        <br /><br />
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            What’s the difference between burial and life insurance?
                        </div>
                        <br />
                        Life insurance provides financial security for dependents, covering debts, mortgages, or education. Burial insurance focuses on funeral and end-of-life expenses, ensuring loved ones are not burdened with those costs.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Can I qualify for burial insurance with a health condition?
                        </div>
                        <br />
                        Yes! Burial insurance typically doesn’t require a medical exam but may involve a health questionnaire that affects premiums. It’s a popular choice for those unable to qualify for traditional life insurance.
                        <br /><br />

                        <div style={{ fontSize: '25px', fontWeight: 'bold' }}>
                            Who should I name as a beneficiary?
                        </div>
                        <br />
                        You can name anyone as your beneficiary, from family members to trusted friends. Ensure they understand your wishes and can responsibly manage the policy’s payout.
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;
