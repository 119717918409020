import React from 'react';

const Header = () => {
    const heroImageStyle = {
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("beach.jpg")',
        height: '70vh', // Changed to viewport height for visibility
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
    };

    const heroTextStyle = {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
    };

    return (
        <div>
            <div style={heroImageStyle} className="d-flex align-items-center justify-content-center">
                <div style={heroTextStyle} className="text-center">
                    <div style={{ fontSize: '30px' }}>
                        Here at Insurance works <br /> we work for you.
                        <br />

                    </div>
                    <div>
                        Secure peace of mind with  comprehensive Health, Medicare, Dental, and Life Insurance policies today.
                    </div>
                    <br />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ marginBottom: '1rem', display: 'flex', fontSize: '15px', padding: '20px' }}>
                            <input
                                type="text"
                                placeholder='Enter Zip'
                                style={{
                                    height: '50px',
                                    border: '1px solid #ced4da',
                                    borderRadius: '4px 0 0 4px',
                                    width: '200px',
                                    outline: 'none'
                                }}
                            />
                            
                            <button
                              type='button'
                              onClick={() => window.location.href = '/get-a-quote'}
                              style={{
                                 border: '1px solid #fff',
                                 borderRadius: '0 4px 4px 0',
                                 width: '120px',
                                 background: '#0b3233',
                                 color: 'white',
                                 borderLeft: 'none'
                              }}
                           >
                              Get A Quote
                           </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
