import Footer from "../Footer";
import Nav from "../Nav";
import Body from "./Body";
import Header from "./Header";



const About = () => {
    return (
        <div style={{}}>
            <Header />
            <Body />
        </div>
    );
}

export default About;