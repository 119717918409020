import Footer from "../Footer";
import Nav from "../Nav";
import Body from "../About/Body";
import Header from "../HomePage/Header";
import Cards from "./Cards";
import Closing from "./Closing";



const HomePage = () => {
    return (
        <div style={{}}>


            <Header />
            <Cards />
            <Closing />
        </div>
    );
}

export default HomePage;