import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import HomePage from './HomePage/HomePage';
import About from './About/About';
import FAQ from './FAQ/FAQ';
import Insurance from './Insurance/Insurance';
import Nav from './Nav';
import Footer from './Footer';
import Form from './Form';


function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        {/* Route for the home page */}
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/get-a-quote" element={<Form />} />
        <Route path='/health' element={<Insurance page={'health'} />} />
        <Route path='/medicare' element={<Insurance page={'medicare'} />} />
        <Route path='/life' element={<Insurance page={'life'} />} />
        <Route path='/dental' element={<Insurance page={'dental'} />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App







