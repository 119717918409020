import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


const Cards = () => {

    const services = [
        {
            "title": "Health Options",
            "description": "Choose between many different Health carriers. Low cost plans thru Market place.",
        },
        {
            "title": "Medical Options",
            "description": "Medicare Advantage and Supplemental plans available.",
        },
        {
            "title": "Life Options",
            "description": "Term Life, Whole Life, Universal Life, and Final Expense, and IUL Policies available.",
        },
    ];


    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.15
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.5
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 100 },
        visible: {
            opacity: 1, y: 0, transition: {
                duration: 1.25
            }
        }
    };

    return (
        <div className="container" id="fundingSolutions" style={{ padding: '50px 0' }}>
            <motion.div
                ref={ref}
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '25px' }}
                variants={containerVariants}
                initial="hidden"
                animate={controls}
            >
                {
                    services.map((loan, index) => (
                        <motion.div
                            key={index}
                            style={{ width: '400px', backgroundColor: 'white', borderRadius: '10px', padding: '25px' }}
                            variants={cardVariants}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <div style={{ color: '#0b3233', fontWeight: 'bold', fontSize: '35px' }}>
                                    {loan.title}
                                </div>
                                <div>
                                    <img src={loan.icon} style={{ height: '30px' }} alt="" />
                                </div>
                            </div>
                            <div>
                                {loan.description}
                            </div>

                            <a href="/about#body" style={{ color: '#004b4f', fontSize: '20px' }}>Learn More</a>


                        </motion.div>
                    ))
                }
            </motion.div>
        </div>
    );
}

export default Cards;
